var render = function render(){var _vm=this,_c=_vm._self._c;return _c('window-default',{ref:"window",attrs:{"resolve":_vm.find,"mock":_vm.mock,"props":_vm.props},on:{"loaded":(obj) => _vm.model = obj,"save":_vm.save},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [_c('window-body',{staticClass:"wrapper-lg"},[_c('e-row',{attrs:{"mr":""}},[_c('e-col',{attrs:{"size":"1"}},[_c('erp-s-field',{attrs:{"label":"ID:"}},[_c('erp-input',{attrs:{"size":"2","readonly":""},model:{value:(_vm.model.id),callback:function ($$v) {_vm.$set(_vm.model, "id", $$v)},expression:"model.id"}})],1)],1)],1),_c('e-row',{attrs:{"mr":""}},[_c('e-col',[_c('erp-s-field',{attrs:{"label":"Descricao:"}},[_c('erp-input',{attrs:{"autofocus":"","size":"2"},model:{value:(_vm.model.nome),callback:function ($$v) {_vm.$set(_vm.model, "nome", $$v)},expression:"model.nome"}})],1)],1)],1),_c('e-row',{attrs:{"mr":""}},[_c('e-col',[_c('erp-s-field',{attrs:{"label":"Disponível no App de Vistoria:"}},[_c('erp-checkbox',{model:{value:(_vm.model.app),callback:function ($$v) {_vm.$set(_vm.model, "app", $$v)},expression:"model.app"}})],1)],1)],1),_c('e-row',{attrs:{"mr":""}},[_c('e-col',[_c('erp-s-field',{attrs:{"label":"Permissão:"}},[_c('erp-select',{attrs:{"required":"","options":[
      {
        label: 'Público',
        value: 0
      },
      {
        label: 'Arrematante',
        value: 1
      },
      {
        label: 'Comitentes',
        value: 2
      },
      {
        label: 'Interno',
        value: 100
      }
    ]},model:{value:(_vm.model.permissao),callback:function ($$v) {_vm.$set(_vm.model, "permissao", $$v)},expression:"model.permissao"}})],1)],1)],1),_c('e-row',{attrs:{"mr":""}},[_c('e-col',[_c('erp-s-field',{attrs:{"label":"Ativo:"}},[_c('erp-checkbox',{model:{value:(_vm.model.active),callback:function ($$v) {_vm.$set(_vm.model, "active", $$v)},expression:"model.active"}})],1)],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }